import {makeStyles} from "@material-ui/styles";
import {useRouter} from "next/router";
import {useContext, useEffect} from "react";
import {SessionContext} from "../providers/session";
import theme from '../theme';

const useStyles = makeStyles({
	christmas: {
        opacity: 0,
        pointerEvents: "none",
	}
}, {name: "CustomStyling"});

export default function CustomStyling() {
    const classes = useStyles();
	const {config} = useContext(SessionContext);
    const router = useRouter();
    const matchPaths = {
        'christmas': [
            'julshop',
            'juleshop',
            'julgranskulor',
            'kransar-och-granar',
            'juldekorationer',
            'julblommor',
            'nutcracker-collection',
            'lux-collection',
            'bon-voyage-collection',
            'cheerful-collection',
            'hepburn-christmas-collection',
            'julebutikk',
            'Joulukauppa',
            'joulukauppa',
            'christmas-shop'
        ],
        'easter': [
            'easter'
        ],
        'green-week': [
            'green-week',
            'green-week-frontpage'
        ]
    };

    useEffect(() => {
        // Set initial height variable
        const doc = document.documentElement;
        doc.style.setProperty('--app-height-initial', `${window.innerHeight}px`);

        // Set dynamic height variable
        const appHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty('--app-height', `${window.innerHeight}px`)
        }

        window.addEventListener('resize', appHeight)

        appHeight();

        return () => {
            window.removeEventListener('resize', appHeight)
        }
    }, []);

    function matchRouter(category) {
        let foundMatch = false;
        let pathArray = router.asPath.split('/');

        pathArray.forEach((path) => {
            if(matchPaths[category].indexOf(path) >= 0) {
                foundMatch = true;
            }
        });

        return foundMatch;
    }

    function onlyNordics() {
        const allowedLanguages = ['sv', 'nb', 'fi', 'dk'];
        const language = config.language.locale;

		return allowedLanguages.indexOf(language) >= 0;
    }

    return (
        <>
            {<style jsx="true">
                {`
                    .navigation > div > ul > .jul,
                    .navigation > div > ul > .julshop,
                    .navigation > div > ul > .easter-shop,
                    .navigation > div > ul > .julebutikk,
                    .navigation > div > ul > .joulukauppa,
                    .navigation > div > ul > .juleshop,
                    .navigation > div > ul > .christmas-shop,
                    .navigation > div > ul > .kerstwinkel
                    {
                        color: #ca0d0d;
                    }
                    
                    #desktop-menu .navigation > div > ul > .slim-aarons,
                    #mobile-menu .navigation > div > ul > .slim-aarons,
                    .navigation > div > ul > .slim-aarons {
                        text-transform: none;
                        
                        > a {
                            position: relative;
                            top: 3px;
                            font-family: Red Bright, serif;
                            font-size: 24px;
                            line-height: 17px;
                            color: #00a3e0;
                        }
                        
                        > button {
                            font-family: 'Red Bright';
                            color: #00a3e0;
                            font-size: 28px;
                            line-height: 17px;
                            letter-spacing: 1px;
                        }
                    }
                    
                    .holiday-frame:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 100;
                        border: 60px solid #fff;
                        border-image-source: url(/images/bg/Happy-deals-wide.png);
                        border-image-slice: 50 50;
                        border-image-width: 45px;
                        
                        @media only screen and (max-width: 767px) {
                            border-image-width: 35px;
                        }
                    }
                    
                    #CybotCookiebotDialogNav,
                    #CybotCookiebotDialogPoweredbyCybot,
                    #CybotCookiebotDialogPoweredByText {
                        display: none;
                    }
                    
                    #CybotCookiebotDialog {
                        width: calc(100% - 30px);
                        border-radius: 3px;
                        font-size: 14px;
                        font-family: Futura, Helvetica, Arial, sans-serif;
                        
                        @media screen and (min-width: 601px) {
                            max-width: 550px;
                        }
                    }
                    
                    #CybotCookiebotDialogPoweredbyImage {
                        max-height: 2em;
                        
                        @media screen and (min-width: 1280px) {
                            max-height: 2.5em;
                        }
                    }
                    
                    #CybotCookiebotDialog *,
                    #CybotCookiebotDialogBodyUnderlay * {
                        font-size: 14px;
                        letter-spacing: 0;
                        
                        @media screen and (max-width: 767px) {
                            font-size: 13px;
                        }
                    }
                    
                    #CybotCookiebotDialogHeader {
                        padding-top: 1.75rem;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                    
                    #CybotCookiebotDialogNav {
                        border-bottom: none;
                    }
                    
                    #CybotCookiebotDialogNavList {
                        justify-content: space-between;
                        padding: 0em 0.5em 0.5em;
                    }
                    
                    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItem {
                        flex: none;
                        margin: 0;
                    }
                    
                    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
                        padding: 1em;
                        font-weight: 400;
                        border-bottom: none;
                        
                        &.CybotCookiebotDialogActive {
                            font-weight: 600;
                            border-bottom: none;
                        }
                    }
                    
                    #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
                        max-height: none;
                        border-bottom: none;
                        
                        .CybotCookiebotFader {
                            display: none;
                        }
                    }
                    
                    #CybotCookiebotDialogFooter {
                        padding-bottom: 1.75rem !important;

                        @media screen and (max-width: 767px) {
                            padding-top: 0;
                            padding-bottom: 1rem !important;
                        }
                    }
                    
                    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
                        justify-content: center;
                    }
                    
                    #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton {
                        border-radius: 0;
                        font-weight: 400;
                        
                        .CybotCookiebotDialogArrow {
                            display: none !important;
                        },
                    }
                    
                    #CybotCookiebotDialogBodyLevelButtonCustomize {
                        text-decoration: underline;
                    }
                    
                    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
                        width: auto !important;
                    }


                    .xmas .loaded {
                        background: ${theme.palette['xmas'].main} !important;
                    }
                    
                    .xmas header {
                        background: ${theme.palette['xmas'].main};
                        border-bottom: 4px solid #ac8e6a;
                        color: #fff !important;
                        fill: #fff !important;
                    }
                    .xmas header > div > div > a {
                        color: #fff !important;
                    }
                    
                    .xmas header > div > div:not(#mobile-menu) svg {
                        color: #fff !important;
                    }
                    
                    .xmas header > div > div:not(#mobile-menu) .cart svg {
                        color: #000 !important;
                    }
                    
                    .xmas header > div > div:not(#mobile-menu) .open svg {
                        color: #000 !important;
                    }
                    
                    .xmas header > div > div:not(#mobile-menu) .open a + svg {
                        color: #fff !important;
                    }
                    
                    .xmas header > div > div:not(#mobile-menu) .focus svg {
                        color: #000 !important;
                    }

                    .xmas header .focus  {
                        color: #000 !important;
                    }

                    .xmas header .focus input {
                        color: #fff !important;
                    }
                    
                    .xmas header svg > * {
                        stroke: currentColor;
                    }
                    
                    .xmas header #mobile-menu > button {
                        color: #fff !important;
                        fill: #fff !important;
                    }

                    .xmas header #desktop-menu {
                        color: rgba(0, 0, 0, 0.87);
                    }
                    
                    .xmas .cms-page {
                        margin-bottom: 100px;
                    }
                    
                    .xmas header #desktop-menu nav > div > ul > li {
                        color: #fff !important;
                    }
                    
                    .xmas #footer {
                        position: relative;
                        display: block;
                    }
                    
                    .xmas #footer::before {
                        content: '';
                        position: relative;
                        background-image: url(/images/footer/flowers_and_text_546.png);
                        background-size: cover;
                        width: 90%;
                        max-width: 546px;
                        height: auto;
                        display: block;
                        z-index: 5;
                        background-repeat: no-repeat;
                        aspect-ratio: 273 / 121;
                        margin: auto;
                        margin-bottom: calc(-0.029 * (min(90%, 546px) * 273 / 121));
                    }

                    .xmas #footer > div {
                        position: relative;
                    }
                    
                    .xmas #footer > div::before, .xmas #footer > div::after {
                        content: '';
                        position: absolute;
                        width: 30%;
                        max-width: 200px;
                        height: auto;
                        bottom: 0;
                        background-repeat: no-repeat;
                    }
                    
                    .xmas #footer > div::before {
                        background-image: url(/images/footer/ornament-left.svg);
                        aspect-ratio: 3 / 5;
                        left: 0;
                    }
                    
                    .xmas #footer > div::after {
                        background-image: url(/images/footer/ornament-right.svg);
                        aspect-ratio: 4.3 / 5;
                        right: 0;
                    }
                    
                    
                    .xmas #footer div {
                        background-color: ${theme.palette['xmas'].main} !important;
                        color: #fff !important;
                    }
                    
                    
                    .xmas #footer img {
                        position: relative;
                    }
                    
                    .xmas .buy-button {
                        background: ${theme.palette['xmas'].main};
                    }
                    
                    .xmas .btn.btn-secondary, .xmas .btn.btn-primary {
                        background: ${theme.palette['xmas'].main};
                    }
              `}
            </style>}

            {matchRouter('christmas') ?
                <style jsx="true">
                    {`
                        .discountBubble {
                            background: #CA0D0D;
                            color: white;
                        }
                        
                        .buy-button {
                            /*background: linear-gradient(181deg, #8d141c, #c12229) !important;*/
                        }
                        
                        .btn.btn-secondary, .btn.btn-primary {
                        /*background: linear-gradient(181deg, #8d141c, #c12229) !important;*/
					}
`}
                </style>
            : ''}

            {matchRouter('easter') ?
                <style jsx="true">
                    {`
                        .discountBubble {
                            background: #ee9583 !important;
                            color: white;
                        }
                        
                        .navigation-button.active,
                        .chosen-product-variant.active,
                        .buy-button {
                            background: #ee9583 !important;
                        }
                        
                        .btn.btn-secondary, .btn.btn-primary {
                            background: #ee9583;//linear-gradient(to top right, rgb(210, 97, 86) 0%, rgb(249, 135, 124) 100%) !important;
                        }
                  `}
                </style>
            : ''}

            {matchRouter('green-week') ?
                <style jsx="true">
                    {`
                        .discountBubble {
                            background: #143e35 !important;
                            color: white;
                        }
                        
                        .navigation-button.active,
                        .chosen-product-variant.active,
                        .buy-button {
                            background: #143e35 !important;
                        }
                        
                        .btn.btn-secondary, .btn.btn-primary, .btn:not(.isBanner) {
                            background: #143e35 !important;//linear-gradient(to top right, rgb(210, 97, 86) 0%, rgb(249, 135, 124) 100%) !important;
                        }
                  `}
                </style>
            : ''}

            {router.asPath === '/' && false ?
                <style jsx="true">
                    {`
                        .saleIndicator {
                            display: flex !important;
                            background-color: #6ccaba !important;
                        }
        
                        .discountBubble, .marketingLabel {
                            background: #6ccaba !important;
                        }
                        
                        .navigation-button.active,
                        .chosen-product-variant.active,
                        .buy-button {
                            background: #6ccaba;
                        }
                        
                        .btn.btn-secondary, .btn.btn-primary {
                            background: #6ccaba;
                        }
                    `}
                </style> : ''}
        </>
    );
}
