import * as Sentry from '@sentry/nextjs';
import {makeStyles} from '@material-ui/styles';
import {Container, Typography} from "@material-ui/core";
import theme from 'components/theme';
import {useRouter} from "next/router";
import {useEffect, useState} from "react";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '100vh',
		padding: '40px 15px',
	},
	title: {
		marginTop: 25,
		fontSize: 24,
		fontWeight: 500
	},
	text: {
		marginTop: 20,
		color: '#666',
		textAlign: 'center'
	}
}, {name: "ErrorPage"});

export default function ErrorPage({ error }) {
	const classes = useStyles();
	const router = useRouter();
	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		if(hasLoaded) {
			window.location.reload();
		}
		Sentry.captureMessage('Oops! Something went wrong...', {
			extra: {
				error: error,
				asPath: router.asPath,
				query: router.query,
			},
			tags: ['oops']
		})
	}, [router.asPath]);

	useEffect(() => {
		setHasLoaded(true);
	}, [])

	return (
		<div>
			<Container className={classes.wrapper}>
				<a href="/"><img alt={theme.logo.alt} width="200" src="/images/newport-logo.png"/></a>
				<Typography className={classes.title} variant="h2">Oops! Something went wrong...</Typography>
				<Typography className={classes.text}>An unknown error has occurred. Please try again later or contact customer service.<br/>Click <a href="/">here</a> to get back to the site!</Typography>
			</Container>
		</div>
	);
}
