var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1731661870909"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import setupSentryFetch from "./tools/fetchSentryUrl";

const activateSentry = () => {
  if (location.host?.includes('localhost')) {
    return;
  }

  const sampleRate = process.env.NODE_ENV === 'production' ? 0.01 : 0;
  Sentry.init({
    dsn: "https://1d4aaf2aa5b421904bb4c2e6f00eb74a@o4507174447087616.ingest.de.sentry.io/4507254987554896",

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: sampleRate,

    denyUrls: [
      /^https:\/\/o2\.mouseflow\.com/,
      /^https:\/\/bat\.bing\.com/,
      /^https:\/\/ams\.creativecdn\.com/,
      /^https:\/\/ct\.pinterest\.com/
    ],

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    ignoreTransactions: [
      '/api/zendesk/chat/activity',
      '/api/tracking/facebook/pageview',
    ],
  });
};

window.addEventListener('CookiebotOnConsentReady', function (event) {
  // @ts-ignore
  const Cookiebot = window?.Cookiebot;
  if (typeof Cookiebot !== 'undefined' && Cookiebot.consent) {
    const statisticsConsent = Cookiebot.consent.statistics;    // true/false

    if (statisticsConsent) {
      activateSentry();
    }
  }
});

setupSentryFetch();
